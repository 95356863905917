<template>
	<div class="app-header">
		<div class="left">
			<!-- <div class="logo"></div> -->
			<div class="text">统一GIS管理平台（DataLink-GIS）</div>
		</div>
		<div class="menus">
			<el-menu
				class="el-menu-demo"
				mode="horizontal"
				background-color="#378aef"
				text-color="#fff"
				active-text-color="#ffd04b"
				router
				:default-active="active"
			>
				<el-menu-item index="/">Monitoring</el-menu-item>
				<!-- <el-menu-item index="/">地名地址解析</el-menu-item>
				<el-menu-item index="/map">一致性对比分析</el-menu-item>
				<el-menu-item index="/othername">楼宇别名管理</el-menu-item>
				<el-menu-item index="/temp-address">临时地址管理</el-menu-item> -->
			</el-menu>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Heade',
	data() {
		return {
			active: '/',
		};
	},
	watch: {
		$route: {
			immediate: true,
			handler({ path }) {
				this.active = path;
			},
		},
	},
};
</script>

<style lang="scss" scoped>
.app-header {
	display: flex;
	box-sizing: border-box;
	width: 100%;
	height: 60px;
	/* color: hsla(0, 0%, 100%, 0.85); */
	box-shadow: 0 2px 8px rgba(0, 21, 41, 0.5);
	transition: padding 0.25s ease;
	position: fixed;
	top: 0;
	z-index: 2000;
	background-color: #378aef;

	.left {
		margin-left: 20px;
		display: flex;
		align-items: center;
		i {
			cursor: pointer;
			font-size: 24px;
			color: #fff;
			margin-right: 20px;
		}
		.line {
			width: 1px;
			height: 28px;
			background: rgba(255, 255, 255, 0.2);
			margin-right: 20px;
		}
		.text {
			font-size: 20px;
			font-weight: bold;
			color: #fff;
			margin: 0 20px;
			letter-spacing: 1px;
		}

		.logo {
			display: block;
			width: 140px;
			height: 60px;
			margin-right: 24px;
			line-height: 60px;
			color: #fff;
			text-align: center;
			font-weight: 600;
			font-size: 18px;
			overflow: hidden;
			//background: url('../../assets/img/logo-white.png') 12% center no-repeat;
			background-size: 100% auto;
		}
	}
	.menus {
		flex: 1;
		padding-left: 30px;
	}
}
::v-deep .el-menu.el-menu--horizontal {
	border-bottom: 0;
}
::v-deep .el-menu--horizontal > .el-menu-item {
	font-size: 16px;
}
::v-deep .el-menu--horizontal > .el-menu-item.is-active {
	font-weight: bold;
}
</style>
